import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import axios from 'axios';
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import {
  Switch,
  Route,
  Redirect,
  useParams,
  useHistory
} from "react-router-dom";
import Contact from './views/Contact';
import Home from './views/Home';
import About from "./views/About";
import SpecificPost from "./views/SpecificPost";
import Compose from "./views/Compose";
import Login from "./views/Login";
import PageNotFound from "./views/PageNotFound";
import Category from "./views/Category";
import Categories from "./views/Categories";
import Newsletter from "./views/Newsletter";
import Unsubscribe from "./views/Unsubscribe";
import Impressum_DE from "./views/Impressum_DE";
import Impressum_EN from './views/Impressum_EN';

// App.jsx is the mainPage of the blog.
// it lists all available routes and what to display when they get called. 
// it also calls all posts from the backend and sends them via props to all pages that need access. 


function App() {

  const [posts, setPosts] = useState([]);
  const [apiToken, setApiToken] = useState("")

  const history = useHistory();
  console.log(apiToken)

  // this fetches all the posts from the database and saves them in the posts const state.
  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL)
    .then(function (response) {
      // handle success
      setPosts(response.data);
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    });
  }, [])

  // this serves the authentification and access for the ComposePage. 
  // if the author has logged in, the apiToken will be set, else it is undefined.
  function secretPage () {
    if (apiToken === '' || apiToken === undefined) {
      return (<Redirect to="/login" />)
    } else {
      return (<Compose apiKey={apiToken} />)
    }
  }

  return (
    // this makes the every page, even if empty, fill out the whole screen
    <div style={{minHeight: '100vh', display: 'flex', flexDirection: 'column'}}>
      <NavBar />
        <Switch>
          {/* these routes are dynamic and serve specific content with many possibilities */}
          <Route path="/posts/:postTitle" children={<Child1 posts={posts}/>}>
          </Route>
          <Route path="/categories/:postCategory" children={<Category posts={posts}/>}>
          </Route>
          {/* these routes name the main pages from the menues */}
          <Route exact path="/categories">
            <Categories/>
          </Route>
          <Route path="/contact">
            <Contact/>
          </Route>
          <Route path="/about">
              <About/>
          </Route>
          {/* these routes concern the impressum and privacy notice */}
          {/* first the german ones */}
          <Route path="/legal-and-privacy-notice/de">
              <Impressum_DE/>
          </Route>
          <Route path="/legal-notice/de">
              <Redirect to="/legal-and-privacy-notice/de"/>
          </Route>
          <Route path="/privacy-notice/de">
              <Redirect to="/legal-and-privacy-notice/de"/>
          </Route>
          {/* then the english ones */}
          <Route path="/legal-and-privacy-notice">
              <Impressum_EN/>
          </Route>
          <Route path="/legal-notice">
              <Redirect to="/legal-and-privacy-notice"/>
          </Route>
          <Route path="/privacy-notice">
              <Redirect to="/legal-and-privacy-notice"/>
          </Route>
          {/* these routes allow the author to create new posts and to protect the composePage */}
          <Route path="/compose" >
            {secretPage()}
          </Route>
          <Route path="/login" >
            <Login setApiToken={setApiToken} />
          </Route>
          {/* home-root: the homePage */}
          <Route exact path="/">
            <Home posts={posts} />
          </Route>
          {/* These routes concern the Cuddledragon Newsletter */}
          <Route path="/newsletter/unsubscribe">
            <Unsubscribe/>
          </Route>
          <Route path="/newsletter">
            <Newsletter/>
          </Route>
          {/* this route is for every route not covered here */}
          <Route>
            <PageNotFound />
          </Route>
        </Switch>
      <Footer/>
    </div>
  );
}

// Child1 says how to proceed to get the right post for the specificPost-Component
function Child1(props) {

  let {postTitle} = useParams();
  // loop through postsArray and find the post where the title equals the param in the URL
  const post = props.posts.find(post => _.kebabCase(postTitle) === _.kebabCase(post.title));
  // if the postTitle is not the same as the title_normalised -> then reload the page but with the title_normalised
  if(postTitle !== _.kebabCase(postTitle)) {
    return (
      <Redirect to={'/posts/' + _.kebabCase(postTitle)} />
    )
  }
  // if post was found -> return this post in the specificPost component
  // the different values are passed via props to the SpecificPost-Component
  if(post) {
    return(
      <SpecificPost img={post.img} title={post.title} content={post.content} id={post.id} category={post.category} />
    )
  }
  // if there is no post with this title: load PageNotFound-Component
  return <PageNotFound/>
};

export default App;