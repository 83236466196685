import React, { useState } from 'react';
import {useFormik} from 'formik';
import axios from 'axios';
import { Editor } from '@tinymce/tinymce-react';
import styles from '../styles/compose.css';

// the composePage serves to create new posts and to send them to the backend. 
// it is only accessible by the author and secured with a password, so that no one can write posts randomly.
// the page contains a ComposeForm using formik with the fields: img, title, content, category.
// the content is entered in a TextEditor from TinyMCE.


function Compose (props) {
  const [mails, setMails] = useState([]);

  const formik = useFormik({
    initialValues: {
      img: '',
      title: '',
      content: '',
      category: ''
    },
    onSubmit: (values, {resetForm}) => {
      alert(JSON.stringify(values, null, 2));
      console.log(values)

      // sends the values from the formik form to the backend and database
      // checks whether the user is logged in, api-Key in the header reveals that
      axios.post(process.env.REACT_APP_API_URL + '/posts', values, {
        headers: {
          'X-API-KEY': props.apiKey
        }
      })
      .then(function (response) {
        console.log(response);
        resetForm();
      })
      .catch(function (error) {
        console.log(error);
      });
    }
  });

  // this adds a fileReader to the page that allows you to choose an img via a file explorer
  const readFile = (e) => {
    if (e.target.files && e.target.files[0]) {

      const FR = new FileReader();
      FR.addEventListener("load", function(e) {
        formik.setFieldValue('img', e.target.result);
      });
      FR.readAsDataURL( e.target.files[0] );
    }
  };

  // this informs formik about the changes in input in the TextEditor in the content-field and sets it as its content-value.
  const handleEditorChange = (content, editor) => {
    console.log('Content was updated:', content);
    formik.setFieldValue('content', content)
  } 

  // gets all Mail adresses from Subscriber-Table when title field gets first clicked.
  function getMailAdesses() {
    axios.get(process.env.REACT_APP_API_URL + '/subscribers')
    .then(function (response) {
      setMails(response.data)
      console.log(mails)
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    });  
  }

  return (
    <main className="compose-main">
      <h1 className="page-title">Compose a post...</h1>

      <section className="container compose-form">
        <div className="row">
          <form className="col-md-12" onSubmit={formik.handleSubmit}>
            <div className="form-group">
            {/* img preview */}
              {
                formik.values.img ? <img src={formik.values.img} alt="Uploaded img" className="img-preview"/> : null
              }
              <input className="form-control-file" name="img" accept="image/*" onChange={readFile} type="file"/>
              <label htmlFor="postImg">Upload your Post Image</label>
            </div>
            {/* formik category field */}
            <div className="form-group">
              <select className="form-control" name="category" {...formik.getFieldProps('category')}>
              <option disabled>Please choose a category</option>
                <option>book reviews</option>
                <option>short stories</option>
                <option>poems</option>
                <option>programming</option>
                <option>impressions</option>
                <option>recipes</option>
                <option>other</option>
              </select>
              <label htmlFor="category">Post Category</label>  
            </div>
            {/* formik title field */}
            <div className="form-group">
              <input className="form-control" name="title" onClick={getMailAdesses} {...formik.getFieldProps('title')} />
              <label htmlFor="title">Your Title</label>
            </div>
            {/* formik content field / tinyMCE TextEditor */}
            <div className="form-group">
              {/* <textarea className="form-control" name="content" {...formik.getFieldProps('content')}></textarea> */}
              <Editor
                className="form-control"
                initialValue=""
                apiKey='vgrvb5o889s5av92lechwzg8in98kn9a053c5pti7vdsy3rw'
                init={{
                  height: 500,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                  ],
                  toolbar:
                    'undo redo | formatselect | bold italic backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | help',
                  paste_as_text: true,
                }}
                onEditorChange={handleEditorChange}
              />
              <label htmlFor="content">Your Content</label>  
            </div>
            <button className="btn btn-lg btn-light float-right button-compose" type="submit">Send</button>
          </form>
        </div>
      </section>
    </main>
  );
};
  /* 
    This are the default props an input element has with formik
    <input
      type="email"
      name="email"
      onChange={handleChange}
      onBlur={handleBlur}
      value={values.email}
  /> */

export default Compose;