import React from "react";
import navbar from "../styles/navbar.css";
import logo from "../images/moonDragon.jpg"
import {Link} from "react-router-dom"

// the NavBar is used on every page at the top, in the same place. 
// It contains links to the other pages such as Home, About and Contact.
// If the screen size changes, the navbar toggles and becomes a list.

function NavBar () {
    return(
        <div>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <Link className="brand-logo" to="/">
                    <img id="logo-img" src={logo} width="70px" height="70px" className="align-middle d-inline-block align-top" alt="logo"/>
                </Link>
                <Link id="logo-title" className="navbar-brand navbar-left visible-xs" to="/">
                    Cuddledragon's Secret Lair
                </Link>
                <Link id="logo-title-xs" className="navbar-brand navbar-left hidden-xs" to="/">
                    Cuddledragon's 
                    <br/>
                    Secret Lair
                </Link>

                {/* <Link className="navbar-brand logo-main" to="/">
                    <img src={logo} width="60" height="60" className="align-middle d-inline-block align-top brand-logo logo-img" alt="logo"/>
                    Cuddledragon's Secret Lair
                </Link> */}

                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggler" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarToggler">
                    <div className="navbar-nav ml-auto">
                        <Link className="nav-item nav-link active" to="/">Home <span className="sr-only">(current)</span></Link>
                        <Link className="nav-item nav-link" to="/about">About</Link>
                        <Link className="nav-item nav-link" to="/contact">Contact</Link>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default NavBar;