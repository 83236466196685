import React from 'react';
import styles from '../styles/popUp.css';
import {Link } from "react-router-dom";

// this PopUp can be used anywhere, at the moment is used in the Newsletter-pages. 
// It contains a message, passed through by props
// and a button that redirects the user to the homePage when clicked.

function PopUpRedirect (props) {

    return (
        <div className="popUp-overlay">
            <div className="popUp-content">
                <button className="close-popUp-btn">
                    <Link to="/" className="popUp-link"><i className="fa fa-times fa-2x" aria-hidden="true"></i></Link>
                </button>
                <p>{props.message}</p>
            </div>
        </div>
    )
}

export default PopUpRedirect;