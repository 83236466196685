import React, {useState} from 'react';
import {useFormik} from 'formik';
import axios from 'axios';
import styles from '../styles/unsubscribe.css';
import dividingLine from '../images/ornamentalRule1.png';
import PopUpReload from '../components/PopUpReload';
import PopUpRedirect from '../components/PopUpRedirect';
import
    {unsubscribeText,
    unsubscribeSuccessMessage,
    unsubscribeFailureMessage}
from '../components/Text'

// this page allows users to unsubscribe from the Cuddledragon newsletter.
// it contains a formik unsubscribeForm with one textField for email. 
// the value is sent to the database where the subscriber is deleted if his email exists. 
// depending on whether the unsubscription was successful or not, a different popUp will be displayed.
// The info-texts and popUp-messages are imported from the Text.jsx and stored in consts.


function Unsubscribe () {

    // consts that will decide which popUp will be loaded after submission
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isFinished, setIsFinished] = useState(false);

    const [touchedEmail, setTouchedEmail] = useState(false);

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        onSubmit: values => {
            setTouchedEmail(false)
            // console.log(values)
            
            // this will post a delete demand to the database.
            // if the email is found in there, the belonging entry will be deleted.
            axios.delete(process.env.REACT_APP_API_URL + '/newsletter/unsubscribe?email=' + values.email)
            .then(function (response) {
                console.log(response);
                setIsSubmitted(true);
                setIsFinished(true);
            })
            .catch(function (error) {
                console.log(error);
                setIsSubmitted(false);
                setIsFinished(true);
            });
        },
    });

    // handleClick uses an eventListener to notice any clicks
    // upon click it will set the touchedEmail to true, thus enabling the required-warning
    function handleClick(event) {
        const name = event.target.name;
        if (name === "email") {
          setTouchedEmail(true)
          console.log(touchedEmail)
        } else {
          console.log("error!")
        }
    };

    return (
        <main className="unsubscribe-main">
            <div className="unsubscribe-box">
                <h3 className="unsubscribe-title">Are you sure that you want to Unsubscribe from the 'Cuddledragon Info'-newsletter?</h3>
                <p className="newsletter-p">{unsubscribeText}</p>

                <img className="dividing-line" src={dividingLine} alt="dividing-line" />

                <section className="container form">
                    <h3 className="unsubscribeForm-title">Please enter your mail so we can cross it off our mailing list.</h3>
                    <div className="row">
                        <form id="signupForm" className="col-md-12" onSubmit={formik.handleSubmit}>
                            <div className="form-group">
                                <input className="form-control" required={touchedEmail} name="email" type="email" onClick={handleClick} onChange={formik.handleChange} value={formik.values.email}/>
                                <label htmlFor="email">Your Email</label>
                            </div>
                            <button className="btn btn-lg form-button btn-success float-right button" >Unsubscribe</button>
                        </form>
                    </div>
                </section>
            </div>
            {/* when the deleteDemand has been executed by the backend, the PopUpRedirect will redirect you to the homePage.
            if the email was not found and the delete process threw an error, the PopUpReload will reload the page so you can try again. */}
            {isSubmitted && isFinished ? <PopUpRedirect message={unsubscribeSuccessMessage}/> : null}
            {!isSubmitted && isFinished ? <PopUpReload message={unsubscribeFailureMessage}/> : null}
        </main>
    )
}

export default Unsubscribe;