import React from 'react';
import {Link} from 'react-router-dom';
import styles from '../styles/impressum.css';
import {
    datenschutzText1EN,
    datenschutzText2EN,
    datenschutzText3EN,
    datenschutzText4EN,
    datenschutzText5EN,
    datenschutzText6EN,
    datenschutzText7EN,
    datenschutzText8EN,
    datenschutzText9EN,
    impressumTextEN,
    loremIpsum
} from '../components/Text'

function Impressum_EN () {

    
    return (
        <main className="impressum-main">
            <div className="box">
                <h1 className="page-title">Legal and Privacy Notice</h1>
                <section className="impressum-links-container">
                    <div>
                        <a href="#legalNotice" className="impressum-link"><i className="fa fa-angle-right folder-icon" aria-hidden="true"></i>Legal Notice</a>
                        <a href="#privacyNotice" className="impressum-link"><i className="fa fa-angle-right folder-icon" aria-hidden="true"></i>Privacy Notice</a>
                    </div>
                    <div>
                        <a href="/legal-and-privacy-notice/de" className="impressum-link version-link"><i className="fa fa-angle-right folder-icon" aria-hidden="true"></i>German Version of Legal and Privacy notice</a>
                    </div>
                </section>
                <section id="legalNotice" className="impressum">
                    <h2 className="impressum-title">Legal Notice</h2>
                    <p style={{textAlign:"center"}}>statements according to § 5 German Telemedia Act (TMG)</p>
                    <p className="impressum-content">{impressumTextEN} </p>
                </section>
                <section id="privacyNotice" className="datenschutzerklaerung">
                    <h2 className="impressum-title">Privacy Notice</h2>
                    <p style={{textAlign:"center"}}>statements according to the General Data Protection Regulation (GDPR)</p>
                    <div className="datenschutzerklaerung-content">
                        <h4 className="datenschutz-subtitle">1) Information on the collection of personal data and contact details of the person responsible</h4>
                        <p className="datenschutz-point">{datenschutzText1EN}</p>
                        <h4 className="datenschutz-subtitle">2) Data collection when visiting our website</h4>
                        <p className="datenschutz-point">{datenschutzText2EN}</p>
                        <h4 className="datenschutz-subtitle">3) Cookies</h4>
                        <p className="datenschutz-point">{datenschutzText3EN}</p>
                        <h4 className="datenschutz-subtitle">4) Analytics</h4>
                        <p className="datenschutz-point">{datenschutzText4EN}</p>
                        <h4 className="datenschutz-subtitle">5) Contact Form</h4>
                        <p className="datenschutz-point">{datenschutzText5EN}</p>
                        <h4 className="datenschutz-subtitle">6) Comments</h4>
                        <p className="datenschutz-point">{datenschutzText6EN}</p>
                        <h4 className="datenschutz-subtitle">7) Newsletter</h4>
                        <p className="datenschutz-point">{datenschutzText7EN}</p>
                        <h4 className="datenschutz-subtitle">8) Third-party tools and others</h4>
                        <p className="datenschutz-point">{datenschutzText8EN}</p>
                        <h4 className="datenschutz-subtitle">9) Rights of the data subject</h4>
                        <p className="datenschutz-point">{datenschutzText9EN}</p>
                    </div>
                </section>
            </div>
        </main>
    )
}

export default Impressum_EN;