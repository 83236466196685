import React, { useState, useEffect, useCallback } from 'react';
import styles from "../styles/specificPost.css";
import CommentForm from "../components/CommentForm";
import Comment from "../components/Comment";
import dividingLine from "../images/ornamentalRule1.png";
import _, { times } from 'lodash';
import axios from 'axios';
import DOMPurify from 'dompurify';
import { Link } from 'react-router-dom';

// Page that shows a specific post after clicking its URL
// gets the post via props (title, content, category, date etc.) from the app.jsx component.
// also displays all comments for that post
// and a CommentForm-Component that allows users to create new comments.

function SpecificPost(props) {
    const [comments, setComments] = useState([]);
    const title_normalised = _.kebabCase(props.title);

    const [img, setImg] = useState();
    // dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.content)}}

    const reloadComments = useCallback(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/posts/${title_normalised}`)
        .then(function (response) {
          setComments(response.data.comments.map(d => ({
              ...d,
              date: new Date(d.date).toString().substring(4,16)
          })));
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    });

    // this gets the right img to the post from the database, to minimize loading times.
    // also gets all the comments to the post from the database and saves them in a state
    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + '/posts/' + title_normalised + '/img')
            .then(res => setImg(res.data || ''))
        reloadComments()
      }, [title_normalised])

    return(
        <main>
            <section className="specific-post" id={props.id}>
                <h1 className="page-title">{props.title}</h1>
                {/* ternary operator: if there is an img, show it, else show nothing for the moment */}
                { img ? <img className="specificPost-img" src={img} alt="specific-post-img" /> : '' }       
                {/* this enables the content to be displayed formatted as the author typed it in the textEditor in the Compose-page  */}
                <p className="specificPost-content" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.content)}} >
                </p>
                <p className="category-folder">
                    <Link className="category-link" to={"/categories/" + _.kebabCase(props.category)}>{props.category}</Link>
                    <i className="fa fa-folder-open category-icon" aria-hidden="true"></i>
                </p>
            </section>

            <img className="dividing-line" src={dividingLine} alt="dividingLine" />

            {/* list of all comments to this post, looping through the comments array */}
            <section className="comments">
                <h2 className="section-title">Comments</h2>
                {comments.map((comment, index) => {
                    return(
                        <Comment key={comment.comment_id} date={comment.date} author={comment.author} content={comment.message} />
                    )
                })}
            </section>

            <div className="input-form">
                <CommentForm title={props.title} setComments={setComments} onAdded={reloadComments} />

            </div>
        </main>
    )
};

export default SpecificPost;