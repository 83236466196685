import React from 'react';
import {
    Redirect,
    useParams,
    Link
} from "react-router-dom";
import Post from '../components/Post';
import _, { now, kebabCase } from 'lodash';
import styles from '../styles/category.css';

// the Category-page lists all the posts from that specific Category.
// it loops through the posts array it gets from the App.jsx via props to find all posts with this category.
// if there are posts in this category, they will be displayed via the Post-Component.
// if there are'nt any posts in this category, the notFoundBox is shown, telling the user that there aren't any posts in this category right now.

function Category(props) {
    let {postCategory} = useParams();
    const foundPosts = props.posts.filter(post => _.kebabCase(postCategory) === _.kebabCase(post.category))
    // if post was found -> return this post in a page with a list of those posts
    // if post was not found, reload the page with kebabCase-name and retry
    if(postCategory !== _.kebabCase(postCategory)) {
      return (
        <Redirect to={'/categories/' + _.kebabCase(postCategory)} />
      )
    }
    return(
    <> 
    <main className="category">
        <section className="category-header row">
            <div className="dropdown sidebar col-md-2 col-lg-2">
                <button className="home-button btn btn-light dropdown-toggle" type="button" id="sidebar" data-toggle="dropdown">
                    <span className="sidebar-title">Categories</span>
                </button>
                {/* sidebar with all categories */}
                <div className="dropdown-menu sidebar">
                    <Link to={'/categories/'} className="sidebar-link dropdown-item"><i className="fa fa-folder-open folder-icon" aria-hidden="true"></i>All</Link>
                    <Link to={'/categories/' + _.kebabCase("Book Reviews")} className="sidebar-link dropdown-item"><i className="fa fa-folder-open folder-icon" aria-hidden="true"></i>Book reviews</Link>
                    <Link to={'/categories/' + _.kebabCase("Short Stories")} className="sidebar-link dropdown-item"><i className="fa fa-folder-open folder-icon" aria-hidden="true"></i>Short Stories</Link>
                    <Link to={'/categories/' + _.kebabCase("Poems")} className="sidebar-link dropdown-item"><i className="fa fa-folder-open folder-icon" aria-hidden="true"></i>Poems</Link>
                    <Link to={'/categories/' + _.kebabCase("Programming")} className="sidebar-link dropdown-item"><i className="fa fa-folder-open folder-icon" aria-hidden="true"></i>Programming</Link>
                    <Link to={'/categories/' + _.kebabCase("Recipes")} className="sidebar-link dropdown-item"><i className="fa fa-folder-open folder-icon" aria-hidden="true"></i>Recipes</Link>
                    <Link to={'/categories/' + _.kebabCase("Impressions")} className="sidebar-link dropdown-item"><i className="fa fa-folder-open folder-icon" aria-hidden="true"></i>Impressions</Link>
                    <Link to={'/categories/' + _.kebabCase("Other")} className="sidebar-link dropdown-item"><i className="fa fa-folder-open folder-icon" aria-hidden="true"></i>Other</Link>
                </div>
            </div>
            <div className="category-title col-md-9 col-lg-9">
                All posts from the <span className="category-name">{postCategory}</span> Category
            </div>       
        </section>
        <section>
            {/* ternary operator: if minimum 1 post was found: display them in a list of all posts
            if no post was found: display the notFoundBox and inform the user that there are no posts in this category */}
            {
                foundPosts.length > 0
                ? foundPosts.map((foundPost, index) => (
                    <Post title={foundPost.title} content={foundPost.content} id={foundPost.id} img={foundPost.img} category={foundPost.category} />
                ))
                : (
                    <div className="notFound-box">
                        <h3 className="category-title">Oops!</h3>
                        <p className="category-text">
                        It seems like there are no treasures exhibited in this part of the Cuddledragon's Secret Lair yet.
                        <br className="about-break"/>
                        But that is only a matter of time. Most probably the Cuddledragon is combing through his treasures right now, looking to remedy this situation. Please be patient.
                        <br className="about-break"/>
                        In the meantime, why don't you have a look around to see if there are other treasures that pique your interest?
                        </p>
                    </div>
                )
            }
        </section>
    </main>
    </>
    )
  }

  export default Category;