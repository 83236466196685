import React from 'react';
import {Link} from 'react-router-dom';
import Post from '../components/Post';
import styles from '../styles/home.css';
import _ from 'lodash';
import {welcomeText, tooManyPosts} from '../components/Text'

// The HomePage serves as the mainPage of the Blog.
// it welcomes the user with a text and lists the fifteen last posts from the database via Post-Components.
// If there are more than 15, only the latest ones will be shown and an infoMessage will appear.
// The info-texts are imported from the Text.jsx and stored in consts.

function Home(props) {
    const countPosts = props.posts.length;

    return(
        <main className="home">
            <section className="welcome">
                <div className="row">
                    <article className="dropdown sidebar col-md-2 col-lg-2">
                        <button className="home-button btn btn-light dropdown-toggle" type="button" id="sidebar" data-toggle="dropdown">
                            <span className="sidebar-title">Categories</span>
                        </button>
                        {/* categories sidebar */}
                        <div className="dropdown-menu sidebar">
                            <Link to={'/categories/'} className="sidebar-link dropdown-item"><i className="fa fa-folder-open folder-icon" aria-hidden="true"></i>All</Link>
                            <Link to={'/categories/' + _.kebabCase("Book Reviews")} className="sidebar-link dropdown-item"><i className="fa fa-folder-open folder-icon" aria-hidden="true"></i>Book reviews</Link>
                            <Link to={'/categories/' + _.kebabCase("Short Stories")} className="sidebar-link dropdown-item"><i className="fa fa-folder-open folder-icon" aria-hidden="true"></i>Short Stories</Link>
                            <Link to={'/categories/' + _.kebabCase("Poems")} className="sidebar-link dropdown-item"><i className="fa fa-folder-open folder-icon" aria-hidden="true"></i>Poems</Link>
                            <Link to={'/categories/' + _.kebabCase("Programming")} className="sidebar-link dropdown-item"><i className="fa fa-folder-open folder-icon" aria-hidden="true"></i>Programming</Link>
                            <Link to={'/categories/' + _.kebabCase("Recipes")} className="sidebar-link dropdown-item"><i className="fa fa-folder-open folder-icon" aria-hidden="true"></i>Recipes</Link>
                            <Link to={'/categories/' + _.kebabCase("Impressions")} className="sidebar-link dropdown-item"><i className="fa fa-folder-open folder-icon" aria-hidden="true"></i>Impressions</Link>
                            <Link to={'/categories/' + _.kebabCase("Other")} className="sidebar-link dropdown-item"><i className="fa fa-folder-open folder-icon" aria-hidden="true"></i>Other</Link>
                        </div>
                    </article>
                    <article className="welcome-text col-md-9 col-lg-9">
                        {welcomeText}
                    </article>
                </div>
            </section>
            {/* the latest posts from the posts-array are shown here in individual Post-Components. */}
            <section>
                {/* the array of posts is "cut off" after 15 posts, so that only 15 posts are ever shown on the website. */}
                {props.posts.slice(0, 15).map((post) => {
                    return(
                        <Post key={post.post_id} img={post.img} title={post.title} content={post.content} category={post.category} />
                    );           
                })}
                {/* if the amount of posts on the blog is greater than 15, the ManyPosts-Component will be shown */}
                {countPosts > 15 ? <ManyPosts /> : null}
            </section>

        </main>
    )
};

// ManyPosts is a function only visible if there are more than 15 posts on the blog.
// Else the homePage would be cluttered
// It links to the different categories to allow users to browse all the posts.
function ManyPosts() {
    return (
        <div className="goodbye">
            <h3 className="goodbye-title">Hello there!</h3>
            <p className="goodbye-text" >{tooManyPosts}</p>
        </div>
    )
}

export default Home;