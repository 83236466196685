import React, {useState, useEffect} from 'react';
import {useFormik} from 'formik';
import axios from 'axios';
import {Link} from 'react-router-dom';
import styles from '../styles/newsletter.css';
import dividingLine from '../images/ornamentalRule1.png';
import PopUpRedirect from '../components/PopUpRedirect';
import PopUpReload from '../components/PopUpReload';

import 
    {signupText,
    signupSuccessMessage,
    signupFailureMessage}
from '../components/Text'

// the Newsletter-page allows users to sign up for the Cuddledragon Newsletter.
// the signup-form is a formik form containing the fields name and email.
// depending on whether the signup was successful or not, a different popUp will be displayed
// The info-texts and popUp-messages are imported from the Text.jsx and stored in consts.


function Newsletter () {

    const validate = values => {
        const errors = {};
        if (values.name.length > 30) {
            errors.name = 'Must be 30 characters or less';
        }
        return errors;
    };

    // consts that will decide which popUp will be loaded after submission
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isFinished, setIsFinished] = useState(false);

    const [touchedName, setTouchedName] = useState(false);
    const [touchedEmail, setTouchedEmail] = useState(false);

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
        },
        validate,
        onSubmit: values => {
            setTouchedName(false)
            setTouchedEmail(false)
            console.log(values)

            // upon submit, the values will be sent to the backend and the database
            axios.post(process.env.REACT_APP_API_URL + '/newsletter', values)
            // if the signup was successful, this will happen
            .then(function (response) {
                console.log(response, response.status);
                setIsSubmitted(true)
                setIsFinished(true)    
            })
            // if there was an error during signup, this will happen
            .catch(function (error) {
                console.log(error);
                setIsSubmitted(false)
                setIsFinished (true)
            })
            // sendWelcomeMail();
        },
    });

    // handleClick uses an eventListener to notice any clicks
    // upon click it will set the touchedx to true, thus enabling the required-warning
    function handleClick(event) {
        const name = event.target.name;
        
        if (name === "name") {
          setTouchedName(true)
          console.log(touchedName)
        } else if (name === "email") {
          setTouchedEmail(true)
          console.log(touchedEmail)
        } else {
          console.log("error!")
        }
    }

    // function sendWelcomeMail () {
    //     // get Email, name value form form
    //     // make
    // }
 
    return (
        <main className="newsletter-main">
            <div className="signUp-form">
                <h1 className="newsletter-title">Never want to miss out on new treasures again?</h1>
                <h2 className="newsletter-subtitle">Then sign up for the Cuddledragon's Newsletter!</h2>
                <p className="newsletter-p">{signupText}</p>

                <img className="dividing-line" src={dividingLine} alt="dividing-line" />

                <section className="container form">
                    <h2 className="signupForm-title">Get the Cuddledragon Info now!</h2>
                    <div className="row">
                        <form id="signupForm" onSubmit={formik.handleSubmit} className="col-md-12">
                            {/* formik name field */}
                            <div className="form-group">
                                <input id="name" className="form-control" required={touchedName} name="name" type="text" onClick={handleClick} onChange={formik.handleChange} value={formik.values.name} />
                                <label htmlFor="name">Your Name</label>
                            </div>
                            {/* formik email field */}
                            <div className="form-group">
                                <input id="email" className="form-control" required={touchedEmail} name="email" type="email" onClick={handleClick} onChange={formik.handleChange} value={formik.values.email} />
                                <label htmlFor="email">Your Email</label>
                            </div>
                            {/* necessary agree to our privacy policy checkbox */}
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="datenschutz"/>
                                <label class="custom-control-label" for="datenschutz" style={{paddingTop:0 + "px"}}>By clicking below to signup, you're agreeing to our <Link to="/legal-and-privacy-notice#privacyNotice">privacy policy</Link>.</label>
                            </div>                           
                            <button className="btn btn-lg form-button btn-success float-right button" type="submit" >Sign Up</button>
                        </form>
                    </div>
                </section>
            </div>
            {/* when the signup has been received by the backend, the PopUpRedirect will redirect you to the homePage.
            when there was an error during the signup, the PopUpReload will reload the page so you can try again. */}
            {isSubmitted && isFinished ? <PopUpRedirect message={signupSuccessMessage}/> : null}
            {!isSubmitted && isFinished ? <PopUpReload message={signupFailureMessage}/> : null}
        </main>
    )
}

export default Newsletter;