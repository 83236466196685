import React, { useState } from "react";
import styles from "../styles/about.css";
import aboutImg from "../images/moonDragon.jpg";
import ornamentalRule from "../images/ornamentalRule1.png";
import AboutInfoBox from "../components/AboutInfoBox";
import HiddenInfo from "../components/HiddenInfo";

import book1 from "../images/book1.jpg";
import write from "../images/write.jpg";
import programmingImg from "../images/programming.jpg";
import marvel from "../images/amazed.jpg";

import
    {aboutText,
    reading,
    writing,
    programming,
    marvelling}
from '../components/Text'

// the About-Page tells the user what the blog is about, and about the Cuddledragon.
// It contains four different AboutInfoBox-components for the subjects reading, writing, programming, marvelling.
// Also, a HiddenInfo about the author is on this page, hidden by a button.
// When the button is pressed, some useState ReactHooks change, making the box visible.
// The info-texts are imported from the Text.jsx and stored in consts.


function About() {

    const [isClicked, setIsClicked] = useState(false);
    const [visibility, setVisibility] = useState(true)

    // handleClick uses an eventListener to see when the button for the HiddenInfo isClicked.
    // Upon Click the states of isClicked and visibility are changed, making the HiddenInfo visible and hiding the button.
    function handleClick() {
        setIsClicked(true)
        setVisibility(false)        
    }
    
    return(
        <main className="about">
            <section className="box about-info-box">
                <h1 className="page-title">About the Cuddledragon</h1>
                <img className="about-img" src={aboutImg} alt="about-img" />
                <p className="cuddledragon-info">{aboutText}</p>
            </section>
            
            <img className="dividing-line" src={ornamentalRule} alt="ornamental dividing line" />
            
            <section>
                <AboutInfoBox imgLeft={true} title="Reading" text={reading} imgSrc={book1} alt="img-reading" />
                <AboutInfoBox imgLeft={false} title="Writing" text={writing} imgSrc={write} alt="img-writing" />
                <AboutInfoBox imgLeft={true} title="Programming" text={programming} imgSrc={programmingImg} alt="img-programming" />
                <AboutInfoBox imgLeft={false} title="Marvelling" text={marvelling} imgSrc={marvel} alt="img-marvelling" />
                
                <img className="dividing-line" src={ornamentalRule} alt="ornamental dividing line" />
                {visibility ? 
                    <div style={{textAlign:"center", paddingBottom:20 + "px"}}>
                        <button className="hidden-button btn btn-light btn-lg text-center" type="submit" onClick={handleClick} >Curious?</button>
                    </div>
                    :
                    null
                }
                {/* if the button is clicked, the HiddenInfo-Component will show, else it will be hidden */}
                {isClicked ? <HiddenInfo/> : null}
            </section>
        </main>
    )
};

export default About;

// AboutInfoBox properties:
/*
imgLeft -> true or false
title -> section-title
text -> paragraph text
imgSrc -> source for img
*/