import React from "react";
import styles from "../styles/footer.css";
import { Link } from "react-router-dom";

// the footer is used on every page at the bottom, in the same position.
// it contains the current year in the copyright section and links to the useletter. 
// it also links to the subscribeTo/unsubscribeFrom Newsletter and the legal and privacy notice

const date = new Date();
const currentYear = date.getFullYear();

function Footer() {
    return(
        <footer className="footer">
            <h5 className="footer-message">Thanks for stopping by. I would love to see you again.</h5>
            <Link to="/newsletter" className="footer-link"><p className="footer-link">Subscribe to the newsletter</p></Link>
            <Link to="/newsletter/unsubscribe" className="footer-link"><p className="footer-link">Unsubscribe from the newsletter</p></Link>
            <Link to="/legal-and-privacy-notice" className="footer-link"><p className="footer-link">Legal notice / Privacy notice</p></Link>
            <p className="copyright">© Copyright {currentYear}</p>
        </footer>
    )
}

export default Footer;