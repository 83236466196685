import React from "react";
import styles from "../styles/contact.css";
import ContactForm from "../components/ContactForm";
import mailImg from "../images/mail.png";
import ornamentalRule from "../images/ornamentalRule1.png"

// The Contact-Page serves to enable users to contact the author via email.
// The form comes from the ContactForm-Component.

function Contact() {
    return(
        <main className="contact">
            <div className="contact-box">
                <img className="contact-img" src={mailImg} alt="contact-img" />
                <h1 className="page-title">Contact</h1>
                <section id="contact-infotext">
                    <p className="contact-subtitle">
                        Seeing you here suggests that you want to leave the Cuddledragon a message.
                        <br/>
                        That is great! It absolutely loves getting mail.
                    </p>
                    <ul className="subject-list">
                        <li>
                            <p className="contact-p">
                                <i>Have you heard of any treasures worth taking into its Secret Lair?</i>
                                <br/>
                                The Cuddledragon would be thrilled to learn about it. Always on tenterhooks, it can't wait to expand its hoard.
                            </p>
                        </li>
                        <li>
                            <p className="contact-p">
                            <i>Do you have any feedback on your visit of its Secret Lair?</i>
                            <br/>
                            The Cuddledragon is always concerned about offering its guests the best visitor experience. Therefore, suggestions for improvement, general impressions and praise - especially praise - are always welcome.
                            </p>
                        </li>
                        <li>
                            <p className="contact-p">
                            <i>Do you have any questions? About anything at all?</i>
                            <br/>
                            There are no dumb questions, only dumb answers. Rest assured, the Cuddledragon will take the time to answer you.
                            </p>
                        </li>
                        <li>
                            <p className="contact-p">
                                <i>Or do you just want to have a nice conversation?</i>
                                <br/>
                                The Cuddledragon is convinced that contact with others successfully appeases the daily madness and may lead to the most curious of subjects. And those crazy conversations just happen to be the most interesting ones. So bring it on! 
                            </p>
                        </li>
                    </ul>
                </section>
                <img className="dividing-line" src={ornamentalRule} alt="dividing-line" />
                <section id="contact-form">
                    <ContactForm />
                </section>
            </div>
        </main>
    )
};

export default Contact;