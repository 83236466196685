import React from 'react';
import {Link} from 'react-router-dom';
import styles from '../styles/text.css'


// ///////////////////////////////////////////////////////////////////////////////////// CATEGORIES-PAGE

export const categoryText = <>
    <p className="text-subtitle">Welcome to the archives of the Cuddledragon’s Secret Lair.</p>
    <br className="text-break"/>
    In here, you will find every piece of treasure that has ever been exhibited in the Secret Lair, even if it has been removed from the main room to make space for new pieces of the Cuddledragon’s valuable hoard.
    <br className="text-break"/>
    To make it easier for you to find your way around, the treasure has been classified into several categories.
    As you go on and delve deeper into the archives, you will find a special room for all of them and some additional information about the pieces of treasure stored inside each category.
    Should one of them peak your interest, feel free to enter and have a look around.
    <br className="text-break"/>
    <br className="text-break"/>
    <p className="text-subtitle">Enjoy!</p>
</>
export const bookReviewText = <>
        The many bookshelves along the walls should make it sufficiently clear: The Cuddledragon absolutely adores reading.
        <br className="text-break"/>
        Every piece of treasure labelled ‘Book Review’ contains information about a new book, mostly a short summary, comments on its strong and weak points etc.
        <br className="text-break"/>
        If you are looking for a new book, the gems in this room are made for you!
    </>
export const shortStoriesText = <>
    In its free time, the Cuddledragon also gave creating its own worlds a try and found out that it really likes writing stories.
    <br className="text-break"/>
    Due to its short attention span however, the stories it writes are mostly short ones. Luckily, that makes them perfect for exhibition.
    <br className="text-break"/>
    So, if you want to discover the tales emerged from the Cuddledragon’s plume, just enter and have a look around the “Short Stories”-room.
</>
export const poemsText = <>
    The magic of the moment encased in beautifully arranged words, often times reminding us of a song, that is a poem.
    <br className="text-break"/>
    As a collector of beautiful things, the Cuddledragon couldn’t possibly have the heart to not make a room exclusively for those precious things.
    <br className="text-break"/>
    In here, you will find mostly home-made poems, but some of the old classics and new brilliants that moved the Cuddledragon and whose publication won’t get it into legal trouble also gained their place in the “Poems”-room.
</>
export const programmingText = <>
    Did you know that the whole Secret Lair has been constructed by the Cuddledragon itself with the help of special programming tools?
    It has learned how to wield them especially for this purpose, but during the process of learning, the world of programming has caught its interest.
    <br className="text-break"/>
    In this room, the Cuddledragon stores reports, stories and small projects from its excursions into the programming world.
    Nothing really groundbreaking though, as it is only a rookie-grammer.
</>
export const recipeText = <>
    The Cuddledragon enjoys eating as much as the next dragon does. Fruit, fish, vegetables, dessert… oh, desserts!
    <br className="text-break"/>
    Anyway, it loves discovering new dishes as long as they are not too spicy and don’t contain cheese.
    A great meal is as good a treasure as a fine book or jewels.
    <br className="text-break"/>
    Behind this door, you find an assortment of recipes the Cuddledragon has come across whilst travelling on the search for new treasures.
    Have a look at them if you want to find a new recipe to try.
</>
export const impressionsText = <>
    Do you know those moments were you are just astounded? Standing there and enjoying the instant?
    Smelling the odour of the rain on concrete, seeing a small flower in front of a barbed-wire-fence or hearing a little child laughing in absolute bliss. Those are moments that make you smile when sitting down in the evenings, rewinding the day in your head.
    <br className="text-break"/>
    In the "Impressions"-room, the Cuddledragon has collected some of the moments that made it smile, cry, laugh or think. Maybe, they touch you too. Why don’t you share your impressions?
</>
export const otherText = <>
    This category is… special. Well, ok, if you insist, the Cuddledragon has to admit that this room is like a broom closet.
    In here, every piece of treasure that does not fit into one of the other categories finds its place.
    <br className="text-break"/>
    Nevertheless, that doesn’t mean that there cannot be especially interesting pieces of treasure in here, waiting to be found by you.
    <br className="text-break"/>
    And as a gem-hoarding dragon, the Cuddledragon knows all about finding treasures in seemingly trashy environments. 
</>

// //////////////////////////////////////////////////////////////////////////////////////// ABOUT-PAGE

export const aboutText = <>
    The Cuddledragon is a really gentle, friendly and curious little dragon. While great at flying, it really sucks at breathing fire. The lack of this skill doesn’t deter it from following its dreams, though. On the contrary, it is quite happy to avoid all the heartburn and stomachache that breathing fire brings about!
    <br className="text-break"/>
    Instead, during the time the other dragons train their fire-breathing-skills to see who can roast dinner the nicest, the Cuddledragon takes wing to discover the world. To satisfy its own curiosity and to gather new treasures to add to its hoard – yes, all dragons love shiny things and valuables and everything they can hoard in general – it never stops exploring.
    <br className="text-break"/>
    By now, it has amassed quite a lot of treasures, and the hoard is still growing. The Cuddledragon is rather content with its life, adding new valuables to the pile, playing tag with the other dragons in the skies and just lying on top of its treasure at times, leisurely dozing. Nevertheless something seems to be missing. Recently it thought about how nice it would be to share its treasure with others. This way, everyone would be able to see the amazing things it has found and they could exchange their impressions.
    <br className="text-break"/>
    In its secret lair, the Cuddledragon has decided to just do that. It has looked through its ever growing pile of treasures, picking out the most beautiful pieces in the hope that the visitors of its lair will have as much fun discovering them as the Cuddledragon did.
</>;
export const reading = <>
    Books! More books! Lots of books! The Cuddledragon can’t get enough of them. It always finds new book stores to browse through, new libraries to explore and new books to get lost in.
    <br className="text-break"/>
    In fact, it is known to only quit a newly discovered library once it has found and read all of the books it hadn’t known before, much to the dismay of the librarians wishing to lock up the library at closing hours.
    <br className="text-break"/>
    To save you from experiencing the wrath of a library’s keeper yourself and in order to spare you endless days and nights spent searching for new reading material, the Cuddledragon scoured his immense bookshelves to present to you its favourite reads.
</>;
export const writing = <>
    Once upon a time, there was a young Cuddledragon that wanted nothing more than to have a good book waiting next to its treasure pile for a leisurely evening read. As you probably know by now – if you have read the long paragraphs before this, that is – the Cuddledragon has a strong weakness for good stories of all kinds. This once though, the unlikely event occurred that the Cuddledragon had no book left to read.
    <br className="text-break"/>
    “If there are no stories to read, no worlds to immerse myself in and no characters to accompany, I shall have to create them myself”.
    <br className="text-break"/>
    Those were the thoughts of the small dragon, and thus it started writing poems, short stories, and not so short stories. In its lair, the Cuddledragon shares with you  its own works, so that you will never have to worry about not having a book waiting for you next to your hoard.
</>;
export const programming = <>
    While travelling throughout the lands on the search for treasures, the Cuddledragon has encountered many people from many countries speaking many different languages. Being a fast learner and fascinated by the prospect of being able to speak with others without language barriers hampering the communication, it has accumulated proficiency in many languages.
    <br className="text-break"/>
    During one particular trip, it learned about programming and that there were also languages involved. Immediately, the Cuddledragon was hooked. Even though it is by no means a programmer – in fact, the term <i>“rookie-grammer”</i> would be more adapt – it still wishes to proudly impart some of the gems of information that it has acquired in this field.
</>;
export const marvelling = <>
    Finding treasures is like looking for a needle in a haystack – tedious and demanding. Through years of training on the job, the Cuddledragon has reached mastery at the skill of finding needles, though it doesn’t know what to do with all of them…
    <br className="text-break"/>
    Anyhow, the real secret behind discovering treasures is to never stop wondering at the world where countless miracles and gems wait to be found. The Cuddledragon has learned to see beauty in the small things, be it a little flower next to a gigantic barbed-wire fence, the smell of the air after a rain shower or a small butterfly landing trustfully on its snout.
    <br className="text-break"/>
    In its secret lair, it wishes to tell you about all the marvellous things it has seen and all of the impressions it has made. Maybe you can tell it about yours?
</>

// ////////////////////////////////////////////////////////////////////////////////// UNSUBSCRIBE-PAGE

export const unsubscribeText = <>
    If you unsuscribe from the Cuddledragon's newsletter, you will not be sent any mails or information about the Secret Lair and the treasures exhibited in it.
    <br className="text-break"/>
    And while the Cuddledragon would regret seeing you go, it respects your decision and wishes you the very best in life.
</>
export const unsubscribeSuccessMessage = <>
    <b>You have successfully unsubscribed from the Cuddledragon's newsletter.</b>
    <br className="text-break"/>
    The Cuddledragon is sad to see you go. But feel free to come visit the Secret Lair anytime. Cookies and tea will be waiting for you.
</>
export const unsubscribeFailureMessage = <>
    <b>Sorry!</b>
    <br className="text-break"/>
    The Cuddledragon couldn't find your email on the mailing list. Is it possible that you have made a spelling mistake?
    <br/>
    Please enter your email again so that the Cuddledragon can cross you off its mailing list.
</>

// ///////////////////////////////////////////////////////////////////////////////////// NEWSLETTER-PAGE

export const signupText = <>
    As soon as you are on the small dragon's mailing list, it will send a maildragon to notify you immediately when a new piece of treasure gets exhibited in the Cuddledragon's Secret Lair.
    <br className="text-break"/>
    You will also be informed about any special events and you may get mails keeping you up to date on whatever mischievious schemes the Cuddledragon is concocting at the moment.
    <br className="text-break"/>
    But don't you worry, the moment you decide that you don't want to receive the newsletter anymore, the maildragons will instantly stop delivering it to you.
</>
export const signupSuccessMessage = <>
    <b>Congratulations!</b>
    <br className="text-break"/>
    You have successfully subscribed to the Cuddledragon's newsletter.
</>
export const signupFailureMessage = <>
    <b>Sorry!</b>
    <br className="text-break"/>
    The Cuddledragon didn't quite understand you.
    Could you please repeat the process so it can properly write down your contact details to put them on its mailing list?
</>

// ///////////////////////////////////////////////////////////////////////////////// PAGE-NOT-FOUND-PAGE

export const pageNotFoundText = <>
    It seems like you were looking for a specific piece of treasure.
    I am afraid that even though the Cuddledragons hoard is immense, this specific gem appears to not be in its possession.
    Maybe it will be added to the lair in the future, but for the moment we hope you will find something else that catches your interest.
</>

// ///////////////////////////////////////////////////////////////////////////////// HOME-PAGE

export const welcomeText = <>
    <h1 className="page-title">Welcome Stranger!</h1>
    <br/>
    Why don’t you come in and have a seat? Please sit down and make yourself at home, I will be right back with some cookies and a refreshing drink. 
    Have a look around while I am in the kitchen. Everything you see in here are pieces of my treasure hoard.
    <br className="text-break"/>
    Who I am? The Cuddledragon of course.
    <br className="text-break"/>
    Let me explain to you what this is about: Right now, you find yourself in my humble abode, my secret dragon lair.
    All those little gems of text and information, those pearls of poetry and literature, those jewels of dreams and impressions, are little treasures that I have collected over the years.
    <br/>
    Don’t be shy, take a closer look, have a think about it. It would be a shame if I was the only one to ever marvel at these beauties.
    Why don’t you find yourself something you are interested in and then we can talk about it later, after teatime? I would love for you to share your thoughts.
    <br className="text-break"/>
    Here, on your left hand side, you can find the inventory. If you are looking for something specific, you will probably find it there, but feel free to browse through the lair at your discretion.
    If you are looking for more information about me to make sure that I am not a dangerous serial killer, you may most likely find it back there, in the middle section with the sign “About”.
    I will now leave you alone to prepare a little snack. If you need me, just yell or come find me in the "Contact"-room on your right hand side.
    <br className="text-break"/>
    <br className="text-break"/>
    <p className="text-subtitle">Have fun exploring my hoard!</p>
</>
export const tooManyPosts = <>
    The Cuddledragon is really sorry, but you seem to have seen all the pieces of treasure being exhibited at the moment.
    <br className="text-break"/>
    To discover more of them, the Cuddledragon advises you to take a look in the <Link to="/categories" className="about-link">archives</Link>, where all the pieces of treasure that have ever been exhibited in the Cuddledragon's Secret Lair are accessible.
    <br className="text-break"/>
    The Cuddledragon hopes that you find what you are searching for, discover something new that you like or that you will come back soon.
</>

// /////////////////////////////////////////////////////////////////////////////// IMPRESSUM - GERMAN - DE

export const impressumTextDE = <>
    <strong>Blogbetreiberin:</strong> <br className="text-break"/> 
    Viktoria Jakobs <br/>
    Brucknerstraße 15 b<br/>
    64347 Griesheim <br/>
    Deutschland <br/>
    <br/>                 
    <strong>Kontakt:</strong><br className="text-break"/> 
    E-Mail: <a href='mailto:max@muster.de'>vicky.kirschbluete@gmail.com</a><br/>
    Kontaktformular: <Link to="https://lair.cuddledragon.com/contact">https://lair.cuddledragon.com/contact</Link> <br/>
    <br/>
    <strong>Journalistisch-redaktionelle Angebote</strong><br/>
    Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV: <br className="text-break"/> 
    Viktoria Jakobs   (Adresse wie oben)
</>
export const datenschutzText1DE = <>
    <strong>1.1</strong>   Ich freue mich, dass Sie meine Website besuchen und bedanke mich für Ihr Interesse.
    Im Folgenden informiere ich Sie über den Umgang mit Ihren personenbezogenen Daten bei Nutzung meines Blogs.
    Personenbezogene Daten sind hierbei alle Daten, mit denen Sie persönlich identifiziert werden können.
    <br className="para-break"/>
    <strong>1.2</strong>   Verantwortlicher für die Datenverarbeitung auf dieser Website im Sinne der Datenschutz-Grundverordnung (DSGVO) ist
    <br className="text-break"/>
    <br/>
    <div style={{paddingLeft: 40 + "px"}}>
        Viktoria Jakobs <br/>
        Brucknerstraße 15 b <br/>
        64347 Griesheim <br/>
        Deutschland <br className="text-break"/>
        Email: vicky.kirschbluete@gmail.com 
    </div>
    <br/>
    <strong>1.3</strong>   Diese Website nutzt aus Sicherheitsgründen und zum Schutz der Übertragung personenbezogener Daten und anderer vertraulicher Inhalte (z.B. Bestellungen oder Anfragen an den Verantwortlichen) eine SSL-bzw. TLS-Verschlüsselung.
    Sie können eine verschlüsselte Verbindung an der Zeichenfolge „https://“ und dem Schloss-Symbol in Ihrer Browserzeile erkennen.
</>
export const datenschutzText2DE = <>
    Bei der bloß informatorischen Nutzung dieser Website, also wenn Sie sich nicht für den Newsletter anmelden oder uns anderweitig Informationen übermitteln,
    erheben wir nur solche Daten, die Ihr Browser an unseren Server übermittelt (sog. „Server-Logfiles“).
    Wenn Sie unsere Website aufrufen, erheben wir die folgenden Daten, die für uns technisch erforderlich sind, um Ihnen die Website anzuzeigen:
    <br className="para-break"/>
    <ul>
        <li style={{listStyleType: "circle"}}>Unsere besuchte Website </li>
        <li style={{listStyleType: "circle"}}>Datum und Uhrzeit zum Zeitpunkt des Zugriffes </li>
        <li style={{listStyleType: "circle"}}>Menge der gesendeten Daten in Byte</li>
        <li style={{listStyleType: "circle"}}>Quelle/Verweis, von welchem Sie auf die Seite gelangten</li>
        <li style={{listStyleType: "circle"}}>Verwendeter Browser </li>
        <li style={{listStyleType: "circle"}}>Verwendetes Betriebssystem </li>
        <li style={{listStyleType: "circle"}}>Verwendete IP-Adresse (ggf.: in anonymisierter Form)</li>
    </ul>
    Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an der Verbesserung der Stabilität und Funktionalität unserer Website.
    Eine Weitergabe oder anderweitige Verwendung der Daten findet nicht statt.
</>
export const datenschutzText3DE = <>
    Diese Webseite benutzt keinerlei Cookies.
</>
export const datenschutzText4DE = <>
    Diese Webseite nutzt keine Webanalysedienste.
</>
export const datenschutzText5DE = <>
    Im Rahmen der Kontaktaufnahme mit uns (z.B. per Kontaktformular oder E-Mail) werden personenbezogene Daten erhoben.
    Im Falle des Kontaktformulars werden als Daten ihr Name (oder ein von Ihnen gewähltes Pseudonym), sowie Ihre Email-Adresse erhoben.
    <br className="text-break"/>
    Diese Daten werden ausschließlich zum Zweck der Beantwortung Ihres Anliegens
    bzw. für die Kontaktaufnahme und die damit verbundene technische Administration gespeichert und verwendet.
    <br className="text-break"/>
    Rechtsgrundlage für die Verarbeitung der Daten ist unser berechtigtes Interesse an der Beantwortung Ihres Anliegens gemäß Art. 6 Abs. 1 lit. f DSGVO.
    <br className="text-break"/>
    Ihre Daten werden nach abschließender Bearbeitung Ihrer Anfrage gelöscht, dies ist der Fall,
    wenn sich aus den Umständen entnehmen lässt, dass der betroffene Sachverhalt abschließend geklärt ist
    und sofern keine gesetzlichen Aufbewahrungspflichten entgegenstehen. 
</>
export const datenschutzText6DE = <>
    Im Rahmen der Kommentarfunktion auf dieser Website werden neben Ihrem Kommentar auch Angaben
    zum Zeitpunkt der Erstellung des Kommentars und der von Ihnen gewählte Kommentatorenname gespeichert und auf der Website veröffentlicht.
    <br className="text-break"/>
    Die Kommentare und die damit verbundenen Daten (Kommentatorenname und Zeitpunkt der Erstellung des Kommentars) werden gespeichert und verbleiben auf unserer Website,
    bis der kommentierte Beitrag vollständig gelöscht wurde oder die Kommentare aus rechtlichen Gründen gelöscht werden müssen (z.B. beleidigende Kommentare).
</>
export const datenschutzText7DE = <>
    Der Blogbetreiber bietet Ihnen einen Newsletter an, in welchem er Sie über aktuelle Geschehnisse und neue Blogbeiträge informiert.
    Da der Blog keine wirtschaftlichen Interessen verfolgt, enthält der Newsletter niemals Werbung.
    <br/>
    Möchten Sie den Newsletter abonnieren, müssen Sie eine valide E-Mail-Adresse sowie Ihren Namen oder ein von Ihnen gewähltes Pseudonym angeben.
    Diese Daten werden dann in einer gesicherten Datenbank des Blogbetreibers gespeichert.
    Sie werden ausschließlich zum Zweck des Newsletterversands und der damit verbundenen technischen Administration gespeichert und verwendet.
    Ihre personenbezogenen Daten werden keinesfalls an Dritte weitergegeben.
    <br className="para-break"/>
    Der Newsletterversand erfolgt durch den Versanddienstleister Mailjet, einer Versandplattform des Anbieters Mailjet SAS, 13-13 bis, rue de l’Aubrac, 75012 Paris, France. <br/>
    Informationen über die Datenschutzbestimmungen des Versanddienstleisters erhalten Sie unter: <Link to="https://www.mailjet.com/privacy-policy/">https://www.mailjet.com/privacy-policy/</Link>.
    <br/>
    Der Versanddienstleister informiert den Blogbetreiber anhand eines Analysetools anonymisiert darüber, wie viele der gesendeten Emails erhalten, geöffnet oder blockiert wurden.
    <br className="para-break"/>
    Wenn Sie den Newsletter abonnieren, erklären Sie sich mit dem Newsletter-Empfang und den erläuterten Verfahren einverstanden.
    Die Speicherdauer der personenbezogenen Daten ist unbefristet, um die Funktionalität des Newsletterversand zu ermöglichen
    und endet automatisch mit Ihrer Kündigung des Newsletter-Abonnements oder der Abschaffung des Newsletters.
    <br className="para-break"/>
    Widerruf und Kündigung: Ihre Einwilligung zum Erhalt des Newsletter können Sie jederzeit widerrufen und somit das Newsletter-Abonnement kündigen.
    Nach Ihrer Kündigung erfolgt die Löschung Ihre personenbezogenen Daten. Ihre Einwilligung in den Newsletterversand erlischt gleichzeitig.
    Den Link zur Kündigung des Newsletter-Abonnements finden Sie am Ende jedes Newsletters.
    Alternativ können Sie das Newsletter-Abonnement auch direkt unter <Link to="/newsletter/unsubscribe">https://lair.cuddledragon.com/newsletter/unsubscribe</Link> kündigen.
</>
export const datenschutzText8DE = <>
    <strong>8.1   GoogleFonts</strong>
    <br className="text-break"/>
    Auf diesem Blog werden externe Schriften, Google Fonts verwendet. Google Fonts ist ein Dienst der Google Inc. ("Google").
    Die Einbindung dieser Web Fonts erfolgt durch einen Serveraufruf, in der Regel ein Server von Google in den USA.
    Hierdurch wird an den Server übermittelt, welche unserer Internetseiten Sie besucht haben.
    Auch wird die IP-Adresse des Browsers des Endgerätes des Besuchers dieser Internetseiten von Google gespeichert.
    <br className="text-break"/>
    Die Nutzung von GoogleFonts erfolgt im Interesse einer einheitlichen und ansprechenden Darstellung unserer Online-Angebote.
    Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.
    Weitere Informationen finden Sie in den Datenschutzhinweisen von Google, die Sie hier abrufen können: <br/>
    <Link to="www.google.com/fonts#AboutPlace:about">www.google.com/fonts#AboutPlace:about</Link> <br/>
    <Link to="www.google.com/policies/privacy/">www.google.com/policies/privacy/</Link>
    <br className="para-break"/>
    <strong>8.2   FontAwesome</strong>
    <br className="text-break"/>
    Diese Seite nutzt zur einheitlichen Darstellung von Icons Services, die von Fonticons, Inc. bereitgestellt werden.
    Beim Aufruf einer Seite lädt Ihr Browser die benötigten Icons in ihren Browsercache, um die Seite korrekt anzuzeigen.
    Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den Servern von Fonticons, Inc. aufnehmen.
    Hierdurch erlangt Fonticons, Inc. Kenntnis darüber, dass über Ihre IP-Adresse unsere Website aufgerufen wurde.
    <br className="text-break"/>
    Die Nutzung von Font Awesome Icons erfolgt im Interesse einer einheitlichen und ansprechenden Darstellung unserer Online-Angebote.
    Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.
    <br/>
    Weitere Informationen zu Font Awesome finden Sie unter <Link to="https://fontawesome.com/help">https://fontawesome.com/help</Link> 
    und in der Datenschutzerklärung von Fonticons, Inc.: <Link to="https://fontawesome.com/privacy">https://fontawesome.com/privacy</Link>. 
</>
export const datenschutzText9DE = <>
    <strong>9.1   Auskunfts- und Interventionsrechte</strong>
    <br className="text-break"/>
    Das geltende Datenschutzrecht gewährt Ihnen gegenüber dem Verantwortlichen hinsichtlich der Verarbeitung Ihrer personenbezogenen Daten umfassende Betroffenenrechte (Auskunfts- und Interventionsrechte), über die wir Sie nachstehend informieren:
    <br className="para-break"/>
    <ul>
        <li style={{listStyleType: "circle"}}>Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob betreffende Daten verarbeitet werden und auf Auskunft über diese Daten sowie auf weitere Informationen und Kopie der Daten entsprechend Art. 15 DSGVO.</li>
        <li style={{listStyleType: "circle"}}>Sie haben entsprechend. Art. 16 DSGVO das Recht, die Vervollständigung der Sie betreffenden Daten oder die Berichtigung der Sie betreffenden unrichtigen Daten zu verlangen.</li>
        <li style={{listStyleType: "circle"}}>Sie haben nach Maßgabe des Art. 17 DSGVO das Recht zu verlangen, dass betreffende Daten unverzüglich gelöscht werden, bzw. alternativ nach Maßgabe des Art. 18 DSGVO eine Einschränkung der Verarbeitung der Daten zu verlangen.</li>
        <li style={{listStyleType: "circle"}}>Sie haben das Recht zu verlangen, dass die Sie betreffenden Daten, die Sie uns bereitgestellt haben nach Maßgabe des Art. 20 DSGVO zu erhalten und deren Übermittlung an andere Verantwortliche zu fordern.</li>
        <li style={{listStyleType: "circle"}}>Sie haben ferner gem. Art. 77 DSGVO das Recht, eine Beschwerde bei der zuständigen Aufsichtsbehörde einzureichen.</li>
    </ul>
    <strong>9.2   Widerrufsrecht</strong>
    <br className="text-break"/>
    Sie haben das Recht, erteilte Einwilligungen gem. Art. 7 Abs. 3 DSGVO mit Wirkung für die Zukunft zu widerrufen
    <br className="para-break"/>
    <strong>9.3   Widerspruchsrecht</strong>
    <br className="text-break"/>
    Sie können der künftigen Verarbeitung der Sie betreffenden Daten nach Maßgabe des Art. 21 DSGVO jederzeit widersprechen.
</>

// ////////////////////////////////////////////////////////////////////////////// IMPRESSUM - ENGLISH - EN

export const impressumTextEN = <>
    <strong>Webmaster</strong> <br className="text-break"/> 
    Viktoria Jakobs <br/>
    Brucknerstraße 15 b <br/>
    64347 Griesheim <br/>
    Germany <br/>
    <br/>                 
    <strong>Contact:</strong><br className="text-break"/> 
    e-mail: <a href='mailto:max@muster.de'>vicky.kirschbluete@gmail.com</a><br/>
    contact form: <Link to="https://lair.cuddledragon.com/contact">https://lair.cuddledragon.com/contact</Link> <br/>
    <br/>
    <strong>Responsible for the content of this website (§ 55 Abs. 2 RStV):</strong>
    <br className="text-break"/> 
    Viktoria Jakobs   (address as aforementioned)
</>
export const datenschutzText1EN = <>
<strong>1.1</strong>   I am pleased that you are visiting my website and would like to thank you for your interest. 
In the following, I will provide you with information on how I handle your personal data when you use my blog. 
Personal data are all data with which you can be personally identified.
<br className="para-break"/>
<strong>1.2</strong>   The person responsible for data processing on this website in accordance with the General Data Protection Regulation (GDPR / DSGVO) is
<br className="text-break"/>
<br/>
<div style={{paddingLeft: 40 + "px"}}>
    Viktoria Jakobs <br/>
    Brucknerstraße 15 b<br/>
    64347 Griesheim <br/>
    Germany <br className="text-break"/>
    E-mail: vicky.kirschbluete@gmail.com 
</div>
<br/>
<strong>1.3</strong>   This site uses SSL or TLS encryption for security reasons and for the protection of the transmission of confidential content, such as the inquiries you send to us as the site operator. 
You can recognize an encrypted connection in your browser’s address line when it changes from “http://” to “https://” and the lock icon is displayed in your browser’s address bar.
<br/>
If SSL or TLS encryption is activated, the data you transfer to us cannot be read by third parties.
</>
export const datenschutzText2EN = <>
    If you use this website for informational purposes only, i.e. if you do not register for the newsletter or provide us with information in any other way, 
    we only collect the data that your browser sends to our server (so-called "server log files"). 
    When you call up our website, we collect the following data, which are of technical necessity for us to show you the website:
    <br className="para-break"/>
    <ul>
        <li style={{listStyleType: "circle"}}>Our visited website </li>
        <li style={{listStyleType: "circle"}}>Date and time of the server request</li>
        <li style={{listStyleType: "circle"}}>Host name of the accessing computer</li>
        <li style={{listStyleType: "circle"}}>Referrer URL</li>
        <li style={{listStyleType: "circle"}}>Browser type and browser version </li>
        <li style={{listStyleType: "circle"}}>Operating system used</li>
        <li style={{listStyleType: "circle"}}>IP address used (possibly: in anonymised form)</li>
    </ul>
    Processing is carried out in accordance with Art. (6) (f) f DSGVO on the basis of our justified interest in improving the stability and functionality of our website. 
    The data will not be passed on or used in any other way.
</>
export const datenschutzText3EN = <>
    This website does not use any cookies.
</>
export const datenschutzText4EN = <>
    This website does not use any sort of analytics, nor does it track your behavior or collect your data.
</>
export const datenschutzText5EN = <>
    Personal data is collected when contacting us (e.g. via contact form or e-mail). 
    In the case of the contact form, your name (or a pseudonym chosen by you) as well as your e-mail address will be collected as data.
    <br className="text-break"/>
    These data are stored and used exclusively for the purpose of answering your request or for contacting you and the associated technical administration.
    <br className="text-break"/>
    The legal basis for the data processing is our legitimate interest in answering your request in accordance with Art. 6 (1) (f) DSGVO.
    <br className="text-break"/>
    Your data will be deleted after the final processing of your enquiry. 
    This is the case if it can be concluded from the circumstances that the matter in question has been conclusively clarified 
    and provided that there are no legal obligations to retain data. 
</>
export const datenschutzText6EN = <>
    As part of the comment function on this website, in addition to your comment, 
    information on the time of the comment's creation and the commentator name you have chosen will be saved and published on the website.
    <br className="text-break"/>
    The comments and related data (commentator name and time of comment creation) are saved and remain on our website 
    until the commented contribution has been completely deleted or the comments have to be deleted for legal reasons (e.g. offensive comments). 
</>
export const datenschutzText7EN = <>
    The blog operator offers you a newsletter in which he informs you about current events and new blog posts.
    As the blog does not pursue any commercial interests, the newsletter never contains advertising.
    <br/>
    If you would like to subscribe to the newsletter, you must provide a valid e-mail address and your name or a pseudonym of your choice. 
    This data is then stored in a secure database owned by the blog operator. They are stored and used exclusively for the purpose of sending the newsletter and the associated technical administration.
    Under no circumstances will your personal data be passed on to third parties.
    <br className="para-break"/>
    The newsletters are sent by the mail service provider Mailjet, a mailing platform of the provider Mailjet SAS, 13-13 bis, rue de l'Aubrac, 75012 Paris, France. 
    Information about the data protection regulations of the mail service provider can be found at: <Link to="https://www.mailjet.com/privacy-policy/">https://www.mailjet.com/privacy-policy/</Link>.
    <br/>
    The mail service provider will inform the blog operator, in anonymised form, of how many of the emails sent have been received, opened or blocked, using an analysis tool.
    <br className="para-break"/>
    By subscribing to the newsletter, you agree to the newsletter reception and the explained procedures. 
    The storage period of personal data is open-ended in order to enable the functionality of the newsletter delivery 
    and ends automatically with your cancellation of the newsletter subscription or the discontinuation of the newsletter.
    <br className="para-break"/>
    Revocation and termination: You can revoke your consent to receive the newsletter at any time and thus cancel the newsletter subscription.
    After your cancellation, your personal data will be deleted. Your consent to receive the newsletter expires at the same time.
    You will find the link to cancel the newsletter subscription at the end of each newsletter.
    Alternatively, you can also cancel the newsletter subscription directly at <Link to="/newsletter/unsubscribe">https://lair.cuddledragon.com/newsletter/unsubscribe</Link>.
</>
export const datenschutzText8EN = <>
    <strong>8.1   GoogleFonts</strong>
    <br className="text-break"/>
    This blog uses external fonts, Google Fonts. Google Fonts is a service of Google Inc. ("Google"). 
    The integration of these Web Fonts is done by a server call, usually a Google server situated in the USA. 
    Through this, it is transmitted to the server which of our Internet pages you have visited. 
    The IP address of the browser of the visitor's end device is also stored by Google. 
    <br className="text-break"/>
    GoogleFonts are used in the interest of a uniform and attractive presentation of our online offers. 
    This represents a legitimate interest within the meaning of Art. 6 (1) (f) DSGVO. 
    Further information can be found in Google's privacy notice, which can be accessed here: <br/>
    <Link to="www.google.com/fonts#AboutPlace:about">www.google.com/fonts#AboutPlace:about</Link> <br/>
    <Link to="www.google.com/policies/privacy/">www.google.com/policies/privacy/</Link>
    <br className="para-break"/>
    <strong>8.2   FontAwesome</strong>
    <br className="text-break"/>
    This site uses services provided by Fonticons, Inc. for the uniform display of icons. 
    When you view a page, your browser loads the required icons into its cache to display the page correctly. 
    For this purpose, the browser you are using must connect to the servers of Fonticons, Inc.. 
    This allows Fonticons, Inc. to know that your IP address has been used to access our site. 
    <br className="text-break"/>
    The use of Font Awesome Icons is in the interest of a consistent and attractive presentation of our online services. 
    This represents a legitimate interest in the sense of Art. 6 Para. (1) (f) DSGVO.
    <br/>
    Further information on Font Awesome can be found at <Link to="https://fontawesome.com/help">https://fontawesome.com/help</Link> 
    and in the privacy notice of Fonticons, Inc.: <Link to="https://fontawesome.com/privacy">https://fontawesome.com/privacy</Link>. 
</>

export const datenschutzText9EN = <>
    <strong>9.1   Rights of information and intervention</strong>
    <br className="text-break"/>
    The applicable data protection law grants you comprehensive data subject rights (rights of information and intervention) vis-à-vis the responsible party with regard to the processing of your personal data, about which we inform you below:
    <br className="para-break"/>
    <ul>
        <li style={{listStyleType: "circle"}}>You have the right to request confirmation as to whether or not data in question is being processed and to obtain information about this data as well as further information and a copy of the data in accordance with Art. 15 DSGVO.</li>
        <li style={{listStyleType: "circle"}}>You have the right to request the completion of data concerning you or the correction of incorrect data concerning you in accordance with Art. 16 DSGVO.</li>
        <li style={{listStyleType: "circle"}}>In accordance with Art. 17 DSGVO, you have the right to demand that data pertaining to you be deleted immediately or, alternatively, in accordance with Art. 18 DSGVO, to demand that the processing of the data be restricted.</li>
        <li style={{listStyleType: "circle"}}>You have the right to demand that the data concerning you which you have made available to us be received in accordance with Art. 20 DSGVO and to demand that it be passed on to other responsible parties.</li>
        <li style={{listStyleType: "circle"}}>You also have the right under Art. 77 DSGVO to lodge a complaint with the responsible supervisory authority.</li>
    </ul>
    <strong>9.2   Right of withdrawal</strong>
    <br className="text-break"/>
    You have the right to revoke consents granted in accordance with Art. 7 Para. 3 DSGVO with effect for the future.
    <br className="para-break"/>
    <strong>9.3   Right of objection</strong>
    <br className="text-break"/>
    You can object to the future processing of data concerning you at any time in accordance with Art. 21 DSGVO.
</>
// ///////////////////////////////////////////////////////////////////////////////////////////////////

export const loremIpsum = <>
    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
    At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
    At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
</>