import React from "react";
import OrnamentalRule from "../images/ornamentalRule1.png";
import vickyImg1 from "../images/vicky1.jpg"
import { Link } from "react-router-dom";

// this is used in the aboutPage, at the bottom.
// Only if a button is pressed on that page will this component be shown.
// It contains a link to the authors personal website at the bottom.
// -> this website does not yet exist, empty link.

// TO COME:
// add real link to authors personal website

function HiddenInfo () {
    return (
        <div className="hidden-info box about">
            <h3 className="hidden-title">The person behind the Cuddledragon</h3>
            <img className="hidden-img" src={vickyImg1} alt="img-vicky" />
            <p className="hidden-info-p">
                As you might have guessed already, the Cuddledragon is not real, even though I would like to think that somewhere out there, a little dragon with a heart of gold and the wish to share the beauty it encountered does exist.
                <br className="about-break"/>
                So, by now you are probably wondering who I am and why I had to create this little dragon character in order to build up a blog instead of just publishing it in my own name
                (if you are not wondering about that or not interested in the answers, then I advise you to ignore this text because it will just be a soliloquy about my awesome self).
                <br className="about-break"/>
                <br className="about-break"/>
                Let me present myself: I am Viktoria, a twenty-ish girl with a really, really big soft spot for dragons (who would have guessed?), and an equally big penchant for books, stories and everything of the kind.
                In the name of truth I have to admit that I once read a dictionary just for the heck of it. 😅
                <br className="about-break"/>
                Anyway, it is true that I could have just published the blog in my name, but – to be honest – I thought that it would be so much nicer if all those wayward posts were bound together in a consistent, story-like context.
                Plus, I really like to write a good story and the character of the Cuddledragon is a part of me since the nickname has been bestowed upon me by my awesome boyfriend.
                Thus, the dragon is merely an alter-ego, created to convey my messages in a way more interesting fashion than I would ever be able to do as my human self.
                <br className="about-break"/>
                By the way, English is neither mine nor the Cuddledragon’s mother tongue. So I would be really grateful if you could just ignore the occasional spelling or grammar mistake. Thanks for being understanding. 😊
            </p>
            <img className="dividing-line" src={OrnamentalRule} alt="dividing-line" />
            <p className="hidden-subtitle">
                Want to find out more about the soul behind the Cuddledragon?
                <br className="about-break"/>
                Then check out <a className="about-link" href="">my personal website</a>!
            </p>
            {/* this website does not yet exist. Needs to be updated when that changes */}
            <p className="hidden-info-explication">
                (Unfortunately, the Cuddledragon has totally messed up the website whilst searching for a specific piece of treasure. Until it has had the time to clean up the chaos, my personal website will be unavailable. Thanks for understanding.
                If you want to contact me, you can reach me via the <Link to="/contact" className="about-link">contact form</Link>. I would be happy to hear from you.)
            </p>
        </div>
    )
}

export default HiddenInfo;