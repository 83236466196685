import React, { useState, useReducer } from 'react';

// This component is used in the Contact-Page. 
// It contains the contactForm with the fields name, email and message. 
// To save the input of the user, the useState Reacthook is used. 
// Upon submitting the form, the user gets a pop-Up notification
// and the message will be opened in his personal email-program. 
// Then the input will be set back to empty.

function ContactForm () {

    const [mail, setMail] = useState({
        name: "",
        email: "",
        message: ""
      });

      // touchedX are states to indicate whether the user has already typed text in the fields, with the default false.
      const [touchedName, setTouchedName] = useState(false);
      const [touchedEmail, setTouchedEmail] = useState(false);
      const [touchedMessage, setTouchedMessage] = useState(false);

      // handleClick uses an eventListener to notice every click into the form fields.
      // Upon click, it looks which field was clicked (name, email or message)
      // and sets the belonging touchedX state to true.
      function handleClick(event) {
        const name = event.target.name;

        if (name === "name") {
          setTouchedName(true)
          console.log(touchedName)
        } else if (name === "email") {
          setTouchedEmail(true)
          console.log(touchedEmail)
        } else if (name === "message") {
          setTouchedMessage(true)
          console.log(touchedMessage)
        } else {
          console.log("error!")
        }
      }
      
      // handleChange is an eventListener looking for change inside the formFields. 
      // Upon change, it saves the change in the mail const state. 
      function handleChange(event) {
        const { name, value } = event.target;
    
        setMail(prevMail => {
          return {
            ...prevMail,
            [name]: value
          };
        });
      }
    
      // submitMail is a function only called when the form is submitted.
      // Upon submit, the user gets an alert, and the mail and touchedX const states are reset to empty bzw. false.
      const submitMail = (event) => {
          alert("Congratulations! Your message has been recorded and will be sent to your own mailing-program by the next available maildragon.")
        setMail({
            name: "",
            email: "",
            message: ""
        });
        setTouchedName(false)
        setTouchedEmail(false)
        setTouchedMessage(false)
      }

    
  // if the touchedx state is false, the formFields will be shown normally.
  // if the touchedx state is true, the formFields will light up red and the required-message will be shown.
  return (
    <div >
        <p className="contactForm-p">Now there is only one question left:</p>
        <h3 className="contactForm-title">Are you brave enough to face a Cuddledragon?</h3>
        
        {/* {touchedName ? required : null} */}

      <div className="container form">
        <div className="row">
          <form id="contactform" className="col-md-12" action="mailto:v.jakobs@freenet.de" method="POST" enctype="text/plain" onSubmit={submitMail}>
            {/* name formField */}
            <div className="form-group">
              <input className="form-control" required={touchedName}  name="name" type="text" onClick={handleClick} onChange={handleChange} value={mail.name} />
              <label htmlFor="name">Your Name</label>
            </div>
            {/* email formField */}
            <div className="form-group">
              <input className="form-control" required={touchedEmail} name="email" type="email" onClick={handleClick} onChange={handleChange} value={mail.email}/>
              <label htmlFor="email">Your Email</label>
            </div>
            {/* message formField */}
            <div className="form-group">
              <textarea className="form-control" required={touchedMessage} name="message" onClick={handleClick} onChange={handleChange} value={mail.message}></textarea>
              <label htmlFor="message">Your Message</label>  
            </div>
            <button className="btn btn-lg btn-success float-right button" type="submit">Send</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;