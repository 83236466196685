import React from "react";

// This component is used in the SpecificPost-page.
// It contains a single comment from the database to this specificPost
// with date, message and author as comment-content.
// Data is passed through by props.


function Comment(props) {
    return(
        <div className="comment">
            <p className="date">{props.date}</p>
            <pre className="comment-content">{props.content}</pre>
            <p className="comment-author">{props.author}</p> 
        </div>
    )
}

export default Comment;