import React from 'react';
import styles from '../styles/impressum.css';
import {
    datenschutzText1DE,
    datenschutzText2DE,
    datenschutzText3DE,
    datenschutzText4DE,
    datenschutzText5DE,
    datenschutzText6DE,
    datenschutzText7DE,
    datenschutzText8DE,
    datenschutzText9DE,
    impressumTextDE,
} from '../components/Text'

function Impressum_DE () {

    
    return (
        <main className="impressum-main">
            <div className="box">
                <h1 className="page-title">Impressum und Datenschutzerklärung</h1>
                <section className="impressum-links-container">
                    <div>
                        <a href="#impressum" className="impressum-link"><i className="fa fa-angle-right folder-icon" aria-hidden="true"></i>Impressum</a>
                        <a href="#datenschutzerklaerung" className="impressum-link"><i className="fa fa-angle-right folder-icon" aria-hidden="true"></i>Datenschutzerklärung</a>
                    </div>
                    <div>
                    <a href="/legal-and-privacy-notice" className="impressum-link version-link"><i className="fa fa-angle-right folder-icon" aria-hidden="true"></i>Back to the English Version of Legal and Privacy notice</a>
                    </div>
                </section>
                <section id="impressum" className="impressum">
                    <h2 className="impressum-title">Impressum</h2>
                    <p style={{textAlign:"center"}}>Angaben gemäß § 5 TMG</p>
                    <p className="impressum-content">{impressumTextDE} </p>
                </section>
                <section id="datenschutzerklaerung" className="datenschutzerklaerung">
                    <h2 className="impressum-title">Datenschutzerklärung</h2>
                    <p style={{textAlign:"center"}}>Angaben gemäß DSGVO</p>
                    <div className="datenschutzerklaerung-content">
                        <h4 className="datenschutz-subtitle">1) Information über die Erhebung personenbezogener Daten und Kontaktdaten des Verantwortlichen</h4>
                        <p className="datenschutz-point">{datenschutzText1DE}</p>
                        <h4 className="datenschutz-subtitle">2) Datenerfassung beim Besuch unserer Website</h4>
                        <p className="datenschutz-point">{datenschutzText2DE}</p>
                        <h4 className="datenschutz-subtitle">3) Cookies</h4>
                        <p className="datenschutz-point">{datenschutzText3DE}</p>
                        <h4 className="datenschutz-subtitle">4) Webanalysedienste</h4>
                        <p className="datenschutz-point">{datenschutzText4DE}</p>
                        <h4 className="datenschutz-subtitle">5) Kontaktaufnahme (Kontaktformular)</h4>
                        <p className="datenschutz-point">{datenschutzText5DE}</p>
                        <h4 className="datenschutz-subtitle">6) Kommentarfunktion</h4>
                        <p className="datenschutz-point">{datenschutzText6DE}</p>
                        <h4 className="datenschutz-subtitle">7) Newsletter</h4>
                        <p className="datenschutz-point">{datenschutzText7DE}</p>
                        <h4 className="datenschutz-subtitle">8) Tools und Sonstiges</h4>
                        <p className="datenschutz-point">{datenschutzText8DE}</p>
                        <h4 className="datenschutz-subtitle">9) Rechte des Betroffenen</h4>
                        <p className="datenschutz-point">{datenschutzText9DE}</p>
                    </div>
                </section>
            </div>
        </main>
    )
}

export default Impressum_DE;