import React from 'react';
import styles from '../styles/categories.css';
import SpecificCategory from "../components/SpecificCategory";
import recipeImg from "../images/recipe.jpg";
import bookReviewImg from "../images/book2.jpg";
import shortStoriesImg from "../images/shortStoriesImg.jpg";
import poemsImg from "../images/poems.jpg";
import coding from "../images/coding.jpg";
import impressionsImg from "../images/impressions1.jpg";
import otherImg1 from "../images/other1.jpg";
import 
    {categoryText,
    bookReviewText,
    shortStoriesText,
    poemsText,
    programmingText,
    recipeText,
    impressionsText,
    otherText}
from '../components/Text'

// The Categories-Page shows information about all the different categories of posts on this blog.
// Using the SpecificCategory-Component, it passes the info through via props.
// The info-texts are imported from the Text.jsx and stored in consts.


function Categories() {
    return(
        <main className="categories"> 
            <section className="categories-description">
                <h1 className="page-title">Categories</h1>
                <p className="categories-text">{categoryText}</p>
            </section>

            <section className="row categories-row">
                <SpecificCategory className="col-md-6 col-lg-6" title="Book Reviews" content={bookReviewText} category="Book Reviews" img={bookReviewImg} alt="bookReviewImg" />
                <SpecificCategory className="col-md-6 col-lg-6" title="Short Stories" content={shortStoriesText} category="Short Stories" img={shortStoriesImg} alt="shortStoriesImg" />
                <SpecificCategory className="col-md-6 col-lg-6" title="Poems" content={poemsText} category="Poems" img={poemsImg} alt="poemsImg" />
                <SpecificCategory className="col-md-6 col-lg-6" title="Programming" content={programmingText} category="Programming" img={coding} alt="programmingImg" />
                <SpecificCategory className="col-md-6 col-lg-6" title="Recipes" content={recipeText} category="recipes" img={recipeImg} alt="recipeImg" />
                <SpecificCategory className="col-md-6 col-lg-6" title="Impressions" content={impressionsText} category="Impressions" img={impressionsImg} alt="impressionsImg" />
                <SpecificCategory className="col-md-6 col-lg-6" title="Other" content={otherText} category="Other" img={otherImg1} alt="otherImg" />
            </section>
        </main>
    )
}

export default Categories;

/* Post Categories:

/ book reviews
/ short stories
/ poems
/ other
/ impressions
/ recipes
/ programming 

*/