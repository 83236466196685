import { useEffect } from "react";
import { useLocation } from "react-router-dom";

// this component makes the page scroll to the top when you change pages 
// instead of staying at the old position on the new page.
// Official component from React, no idea how it works though... ^^'

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default ScrollToTop;