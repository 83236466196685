import React from "react";
import {Link} from "react-router-dom"
import _ from 'lodash';

// the SpecificCategory-component is used in the Categories-page. 
// it shows the different categories from this blog, with a short description. 
// reusable because of softcoded content, that is passed through by props such as
// img, img-alt, className, title, content, category. 
// there is a link leading to an overview of all posts from this category.

function SpecificCategory(props) {
    return(
        <div className={props.className}>
            <div className="specific-category">
                <img className="specific-category-img" src={props.img} alt={props.alt} />
                <div className="specific-category-description">
                    <h3 className="specific-category-title">{props.title}</h3>
                    <p className="specific-category-content">{props.content}</p>
                    <div className="post-category">
                        <i className="fa fa-folder-open post-folder-icon" aria-hidden="true"></i>
                        <Link to={"/categories/" + _.kebabCase(props.category)} className="categories-link">{props.category}</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SpecificCategory;

/* Post Categories:

/ book reviews
/ short stories
/ poems
/ other
/ impressions
/ recipes
/ programming 

*/