import React from "react";
import {Link} from 'react-router-dom';
import sign from "../images/streetSigns.jpg";
import styles from "../styles/pageNotFound.css";
import {pageNotFoundText} from '../components/Text'

// this Component is there for every user request to an URL that is not served by backend or front-end.
// The info-text is imported from the Text.jsx and stored in const.

function PageNotFound () {
 return (
    <main className="main-404">
        <div className="error-box">
            <img className="error-img" src={sign} alt="error-img" />
            <h1 className="error-message">Ooops! The page you were looking for could not be found.</h1>
            <p className="error-p">{pageNotFoundText}</p>
            <button className="error-button btn btn-light"><Link to="/" className="error-link">Click here to get back to the main page</Link></button>
        </div>    
    </main>
 )
}

export default PageNotFound;