import React, {useState} from 'react';
import { useFormik, Formik } from 'formik';
import axios from 'axios';
import _ from 'lodash';
import PopUpReload from './PopUpReload';

// the CommentForm is used on the specificPost-Page. 
// it contains two formFields for name and message and upon submit adds the currentDate. 
// The form works with Formik, so the values get validated and updated and saved automatically. 
// Upon submit, the inputData gets send to the backend via axios and then saved to the database. 
// Also, a popUp should show up, that tells the user the comment was added and that, when clicked, will close.

const validate = values => {
   const errors = {};
   if (values.author.length > 30) {
    errors.author = 'Must be 30 characters or less';
  }
  return errors;
};

function CommentForm (props) {
  // title_normalised gets the title of the post this new Comment will belong to.
  const title_normalised = _.kebabCase(props.title);
  const [showPopUp, setShowPopUp] = useState(false);

  // the successMessage will be passed as props into the PopUpReload-Component.
  const successMessage = <>
    <b>Congratulations!</b>
    <br className="about-break"/>
    Your comment has successfuly been sent to the Cuddledragon. When you click on the button, the page will refresh and you will find your message pinned to the comment-board.
  </>
  
  // formik sets the initial values in the formFields (empty in this case)
  // automatically updates the content of the formFields into its values const
  // and on submit sets the setIsSubmitted const to true (thus making the popUp show)
  // and on submit calls axios.
  const formik = useFormik({
    initialValues: {
      author: '',
      message: '',
    },
    validate,
    onSubmit: (values, {resetForm}) => {
      console.log(values)

      // axios sends the values from the formik form to the backend, using the title_normalised
      // to save it in the database with the right post.
      // Then the form is resetted.
      axios.post(process.env.REACT_APP_API_URL + '/posts/' + title_normalised, values)
      .then(function (response) {
        console.log(response);
        resetForm();
        setShowPopUp(true);
        props.onAdded();
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
    },
  });

  return (
    <div >
      <h3 className="commentForm-title">A penny for your thoughts...</h3>
      <p className="subtitle">I would love to hear your opinion, so feel free to share your thoughts.</p>

      <div className="container form">
        <div className="row">
          <form className="col-md-12" onSubmit={formik.handleSubmit}>
            {/* formik author field */}
            <div className="form-group">
              <input className="form-control" required name="author" {...formik.getFieldProps('author')} />
              <label htmlFor="author">Your Name</label>
              {formik.touched.name && formik.errors.name ? (
              <div className="errorMessage">{formik.errors.name}</div>
              ) : null}
            </div>
            {/* formik message field */}
            <div className="form-group">
              <textarea className="form-control" required name="message" {...formik.getFieldProps('message')}></textarea>
              <label htmlFor="message">Your Message</label>  
            </div>
            <button className="btn btn-lg btn-success float-right button" type="submit">Send</button>
          </form>
        </div>
      </div>
      {/* ternary operator: if the form is submitted (visible through the state of the isSubmitted const)
      then show the PopUpReload-Component,
      if the form is not submitted, show nothing here. */}
      {showPopUp ? <PopUpReload message={successMessage} onClose={() => {setShowPopUp(false)}}/> : null}
    </div>
  );
};

/* 
  These are the default props an input element has by using formik
  <input
    type="email"
    name="email"
    onChange={handleChange}
    onBlur={handleBlur}
    value={values.email}
/> */

export default CommentForm;

