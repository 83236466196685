import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import axios from 'axios';
import {useFormik} from 'formik';
import styles from "../styles/login.css";

// The Login-Page serves to authenticate the author before she can compose a new post. 
// it contains a formik Form that collects the password.
// On submit, the value is sent to the backend, where it gets compared with the real password.
// if the entered password is correct, the statusCode 200 is the response and we get redirected to the compose-page. 
// if the password is not correct, the form is emptied and we have to retry. 

function Login(props) {

  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      password: ""
    },
    onSubmit: (values, {resetForm}) => {
      alert(JSON.stringify(values, null, 2));
      console.log(values)
      // on submit, the value is posted to the backend via axios
      // and the password gets checked in the backend
      axios.post(process.env.REACT_APP_API_URL + '/auth', values)
      .then(function (response) {
        // handle success
        // the apiToken gets set so we are authenticated to access the compose-page and we are redirected there
        if (response.status === 200) {
          props.setApiToken(response.data)
          resetForm()
          history.push("/compose");
        }
      })
      // handle error
      // the statusCode gets shown, the form reset and we have to enter the password anew
      .catch(function (error) {
        resetForm()
        console.log(error);
        return (alert("StatusCode 401: The password is not correct."))
      });
    },
  });

  return (
    <main className="login">
      <div className="login-box ">
        <p className="contactForm-p">Behind this door lies the treasure hoard. Only the Cuddledragon has access to them.</p>
        <h1 className="contactForm-title">Are you the Cuddledragon?</h1>
        
        <section className="container form">
          <div className="row">
            <form id="contactform" className="col-md-12" onSubmit={formik.handleSubmit}>
              <div className="form-group">
                <input className="form-control" required name="password" {...formik.getFieldProps('password')} type="password"/>
                <label htmlFor="password">The Password</label>
              </div>
              <button className="btn btn-lg btn-success float-right button" type="submit">Send</button>
            </form>
          </div>
        </section>
      </div>
    </main>
  );
};

export default Login