import React, { useEffect, useState } from "react";
import {Link} from "react-router-dom"
import DOMPurify from 'dompurify';
import Axios from "axios";
import _, { times } from 'lodash'

// this component is used on the homePage to display a single post.
// Reusable because the data is not hardcoded but passed through by props such as title, content, category. 
// the img gets directly called from the database using the title_normalised and axios to reduce loading time. 
// the content of the post gets sliced after 100 chars because it is just a preview.
// By clicking on the SeeMore-link you get redirected to the specificPost-page of this post.

// shorten cuts the postContent of after 100 chars to enable preview-mode.
function shorten(s = '') {
    const tooLong = s.length > 100;
    if(!tooLong) {
        return s;
    }
    const splitString = s.substring(0,100).split(' ');
    return splitString.slice(0, splitString.length - 1).join(' ') + ' ...';
}

function Post(props) {

    const [img, setImg] = useState();
    const title_normalised = _.kebabCase(props.title);
    // gets the belonging img to this specific Post from the backend
    useEffect(() => {
        Axios.get(process.env.REACT_APP_API_URL + '/posts/' + title_normalised + '/img')
            .then(res => setImg(res.data || ''))
    }, [props.title])

    return(
        <div className="post" id={props.id}>
            <img className="post-img" src={img} alt="post-img" />
            <div className="post-description">
                <h3 className="post-title">{props.title}</h3>
                <p className="post-content" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(shorten(props.content || ''))}}></p>
                <Link to={"/posts/" + title_normalised} className="post-link">Read more ...</Link>
                <div className="post-category">
                    <i className="fa fa-folder-open post-folder-icon" aria-hidden="true"></i>
                    <Link to={"/categories/" + props.category} className="post-category-link">{props.category}</Link>
                </div>
            </div>
        </div>
    )
}
export default Post;

/* Post Categories:

/ book reviews
/ short stories
/ poems
/ other
/ impressions
/ recipes
/ programming 

*/