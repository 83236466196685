import React from "react";

// Used in the About-part of the blog.
// The four infoBoxes have a title, content and an image each,
// passed through by props and describing the Cuddledragon alias the author.
// The ternary operator decides the layout of the infoBox depending on
// whether the image is left or right in the boxes.

function AboutInfoBox (props) {
    return (
        // if props.imgLeft = true -> then do this
        props.imgLeft ? (
                        <>
                            <div className="detailed-info box row">
                                <img className="info-img col-md-4 col-lg-4" src={props.imgSrc} alt={props.alt} />
                                <div className="col-md-8 col-lg-8">
                                    <h3 className="info-title">{props.title}</h3>
                                    <p className="info-p">{props.text}</p>
                                </div>
                            </div>
                            
                        </>
                    ) : (
                        // if props.imgLeft = false -> then do this
                        <>
                            <div className="detailed-info box row">           
                                <img className="info-img col-md-3 col-lg-3 d-md-none" src={props.imgSrc} alt={props.alt} />
                                <div className="col-md-8 col-lg-8">
                                    <h3 className="info-title">{props.title}</h3>
                                    <p className="info-p">{props.text}</p>
                                </div>
                                {/* <div className="col-md-1 col-lg-1 d-none d-md-block"></div> */}
                                <img className="info-img col-md-4 col-lg-4 d-none d-md-block" src={props.imgSrc} alt={props.alt} />
                            </div>
                            
                        </>
                    )
    )
}

export default AboutInfoBox;